import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { scaleLinear, scaleTime, } from '@visx/scale';
import { useTheme } from '@mui/material';
import { curveLinear } from '@visx/curve';
import { AreaClosed, LinePath } from '@visx/shape';
import { extent } from '@visx/vendor/d3-array';
import { useCallback, useMemo } from 'react';
import { GridRows } from '@visx/grid';
import { AxisBottom, AxisRight } from '@visx/axis';
import { format } from 'date-fns';
import { LinearGradient } from '@visx/gradient';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { formatUSD } from '@shared/lib/numbers';
import { getLocale } from '@utils/date-time';
import { useAppSelector } from '@app/store/Hooks';
import { defineTooltipPlacement } from './utils';
const GraphWidth = 860;
const GraphHeight = 158;
const TooltipWidth = 210;
const CurveWidth = 768;
const CurveHeight = 120;
const tooltipStyles = {
    position: 'absolute',
    width: TooltipWidth,
    top: 0,
    left: 0,
    display: 'inline-flex',
    gap: '6px',
    backgroundColor: 'rgba(17, 24, 28, 0.75)',
    padding: '4px 8px',
    borderRadius: '4px',
    boxSizing: 'border-box',
};
const CurveContainer = styled.div(props => ({
    position: 'relative',
    width: props.graphWidth,
    height: props.graphHeight,
}));
const TooltipDate = styled.span(() => ({
    color: '#D6DCE0',
}));
const TooltipValue = styled.span();
const Svg = styled.svg(() => ({
    width: '100%',
    height: 'inherit',
    overflow: 'visible',
}));
const XAxisContainer = styled.g(() => ({
    transform: `translate(0, calc(${CurveHeight - 1}px))`,
    '& .OverallBalanceCurve__XAxis text': {
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: '-0.084px',
        fill: '#ABB4BB',
    },
}));
const CurveRect = styled.rect(() => ({
    fill: 'transparent',
    width: CurveWidth,
    height: CurveHeight,
}));
const YAxisContainer = styled.g(() => ({
    // 8px is "x" attribute value of tspan\svg elements inside axis
    // so there`re no ways to affect on it, only via styles
    transform: `translate(calc(${CurveWidth - 8 + 56}px), 0)`,
    '& .OverallBalanceCurve__YAxis text': {
        x: 0,
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: '-0.084px',
        fill: '#ABB4BB',
        dx: '100%',
        textAnchor: 'end',
    },
    '& .OverallBalanceCurve__YAxis svg': {
        x: 0,
        width: 56,
    },
    '& .OverallBalanceCurve__YAxis tspan': {
        x: 0,
    },
}));
const GridRowsStyled = styled(GridRows)(() => ({
    '& .visx-line': {
        stroke: '#EFF1F3',
        strokeDasharray: '4 4',
    },
}));
const TooltipLine = styled.path(props => ({
    visibility: props.isVisible ? 'visible' : 'hidden',
    stroke: props.theme.palette.info.main,
    strokeDasharray: '4 4',
    transform: props.xCoord ? `translateX(${props.xCoord}px)` : '',
}));
const TooltipDotCircle = styled.circle(props => ({
    fill: 'transparent',
    stroke: props.theme.palette.info.main,
    strokeWidth: 2,
    visibility: props.isVisible ? 'visible' : 'hidden',
    transform: `translate(${props.x || 0}px, ${props.y || 0}px)`,
}));
const CurveSvgContainer = styled.svg();
const TooltipStyled = styled(TooltipWithBounds, { shouldForwardProp: propName => propName !== 'isVisible' })(props => ({
    '&.visx-tooltip': {
        visibility: props.isVisible ? 'visible' : 'hidden',
        backgroundColor: props.theme.palette.text.primary,
        color: '#fff',
        pointerEvents: 'none',
        fontSize: 14,
        letterSpacing: '-0.084px',
        lineHeight: '20px',
    },
}));
const OverallBalanceCurve = ({ data, defaultRange, isLoading = false, }) => {
    const theme = useTheme();
    const { language } = useAppSelector((state) => state.app);
    const getX = (point) => point[0];
    const getY = (point) => point[1];
    const { curveDots, } = useMemo(() => {
        const result = data.reduce((acc, val, index) => {
            const key = `${new Date(val[0]).getFullYear()}-${new Date(val[0]).getMonth()}-${new Date(val[0]).getDate()}`;
            return ({
                curveDots: Object.assign(Object.assign({}, acc.curveDots), { [key]: index }),
            });
        }, {
            curveDots: {},
        });
        return result;
    }, [data]);
    const scaleX = useMemo(() => {
        if (data.length) {
            return scaleTime({
                domain: extent(data, getX),
                range: [0, CurveWidth],
                nice: false,
            });
        }
        return scaleTime({
            domain: extent(defaultRange, getX),
            range: [0, CurveWidth],
            nice: false,
        });
    }, [data, defaultRange]);
    const scaleY = useMemo(() => {
        if (data.length) {
            return scaleLinear({
                domain: extent(data, getY),
                range: [CurveHeight, 0],
                nice: true,
            });
        }
        return scaleLinear({
            domain: [0, 1000],
            range: [CurveHeight, 0],
        });
    }, [data, defaultRange]);
    const scaleYFormat = (amount) => formatUSD(Math.round(amount * 100) / 100);
    const scaleXFormat = (date) => format(date, 'dd MMM', { locale: getLocale(language || 'en') });
    const { tooltipOpen, tooltipData, hideTooltip, showTooltip, tooltipLeft, tooltipTop, } = useTooltip({});
    const handleTooltipPosition = useCallback((event) => {
        if (data.length) {
            const { x } = localPoint(event) || { x: 0 };
            const x0 = scaleX.invert(x);
            const key = `${new Date(x0).getFullYear()}-${new Date(x0).getMonth()}-${new Date(x0).getDate()}`;
            const index = curveDots[key];
            const d0 = data[index > 0 ? index - 1 : 0];
            const d1 = data[index];
            let d = d0;
            if (d1 && getX(d1)) {
                d =
                    x0.valueOf() - getX(d0).valueOf() <
                        getX(d1).valueOf() - x0.valueOf() ?
                        d1 :
                        d0;
            }
            const { tooltipLeft: tooltipX, tooltipTop: tooltipY } = defineTooltipPlacement({
                tooltipWidth: TooltipWidth,
                coords: d,
                graphWidth: GraphWidth,
                scaleX,
                scaleY,
            });
            showTooltip({
                tooltipData: {
                    date: `${(d === null || d === void 0 ? void 0 : d.length) ? format(new Date(d[0]), 'MMMM dd, hh:mm', { locale: getLocale(language || 'en') }) : ''}`,
                    value: d ? formatUSD(Math.round((getY(d)) * 100) / 100) : formatUSD(0.00),
                    xCoord: d ? scaleX(getX(d)) : 0,
                    yCoord: d ? scaleY(getY(d)) : 0,
                },
                tooltipLeft: tooltipX,
                tooltipTop: tooltipY,
            });
        }
    }, [showTooltip, scaleX, scaleY, data, language, curveDots]);
    return (_jsxs(CurveContainer, { graphWidth: GraphWidth, graphHeight: GraphHeight, children: [_jsxs(Svg, { viewBox: `0 0 ${GraphWidth} ${CurveHeight}`, children: [_jsxs(CurveSvgContainer, { viewBox: `0 0 ${GraphWidth} ${CurveHeight}`, children: [_jsx(LinearGradient, { id: 'balance-background-gradient', from: theme.palette.info.main, fromOpacity: 0.2, fromOffset: '0%', to: theme.palette.info.main, toOffset: 100, toOpacity: 0 }), _jsx(GridRowsStyled, { scale: scaleY, width: CurveWidth, numTicks: 3 }), !isLoading &&
                                _jsx(LinePath, { x: (d) => scaleX(getX(d)), y: (d) => scaleY(getY(d)), curve: curveLinear, data: (data === null || data === void 0 ? void 0 : data.length) ? data : defaultRange, stroke: theme.palette.info.main }), _jsx(AreaClosed, { data: data, x: (d) => scaleX(getX(d)), y: (d) => scaleY(getY(d)), yScale: scaleY, fill: 'url(#balance-background-gradient)' }), _jsx(TooltipLine, { isVisible: tooltipOpen, xCoord: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.xCoord, d: `M0 0 L0 ${CurveHeight}` }), _jsx(TooltipDotCircle, { isVisible: tooltipOpen, x: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.xCoord, y: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.yCoord, r: 3 }), _jsx(CurveRect, { onTouchStart: handleTooltipPosition, onTouchMove: handleTooltipPosition, onMouseMove: handleTooltipPosition, onMouseLeave: hideTooltip })] }), !isLoading &&
                        _jsx(YAxisContainer, { children: _jsx(AxisRight, { axisClassName: 'OverallBalanceCurve__YAxis', scale: scaleY, tickFormat: (value) => scaleYFormat(value), numTicks: 3, hideAxisLine: true, hideTicks: true }) }), !isLoading &&
                        _jsx(XAxisContainer, { children: _jsx(AxisBottom, { axisClassName: 'OverallBalanceCurve__XAxis', scale: scaleX, tickFormat: (date) => scaleXFormat(date), numTicks: 6, hideAxisLine: true, hideTicks: true }) })] }), _jsxs(TooltipStyled, { isVisible: tooltipOpen, style: Object.assign(Object.assign({}, tooltipStyles), { transform: `translate(${tooltipLeft || 0}px, ${tooltipTop || 0}px)` }), children: [_jsx(TooltipDate, { children: (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.date) || '' }), _jsx(TooltipValue, { children: (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.value) || '' })] })] }));
};
export default OverallBalanceCurve;
